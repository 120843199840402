import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`client/subscription?${p}`);
  },
  create(data) {
    return api.post(`client/subscription`, data);
  },
  update(data) {
    return api.put(`client/subscription/${data.id}`, data);
  },
  confirm(id) {
    return api.put(`client/subscription/confirmed/${id}`);
  },
  deleteSubscription(id) {
    return api.delete(`client/subscription/${id}`);
  },
  last(id) {
    return api.get(`client/subscription/last?customer_id=${id}`);
  },
  subscriptionPay(data) {
    return api.put(`client/subscription/paid/${data.id}`, {
      paid_at: data.paid_at,
    });
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`client/subscription?${p}`, {
      responseType: "blob",
    });
  },
  checkFtthSubscriptionCustomerPrice(customer_id, account_type_id, period) {
    return api.get(
      `client/subscription/price?customer_id=${customer_id}&account_type_id=${account_type_id}&period=${period}`
    );
  },
  checkRenew(id) {
    return api.get(`client/customer/renew/${id}`);
  },
  renew(data) {
    return api.post(`client/customer/renew/${data.customer_id}`, data);
  },
};
