import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";
const subscriptionsApi = factory.get("subscriptions");
const taskApi = factory.get("task");

const initialState = {
  subscriptions: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      mobile: "",
      customer_id: null,
      start_date: null,
      end_date: null,
      account_type_id: null,
      is_confirmed: null,
      is_paid: null,
      is_free: null,
    },
    dialog: false,
  },
  subscription: {
    dialog: false,
    type: "add",
    loading: false,
    form: {
      id: "",
      customer_id: null,
      date: dayjs().format("YYYY-MM-DD"),
      account_type_id: null,
      amount: 0,
      note: "",
      is_confirmed: 0,
      is_paid: false,
      paid_at: null,
      offer_id: null,
      period: 1,
    },
    errors: {},
  },
  subscribers_history: {
    data: [],
    loading: false,
  },
  subscription_pay: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      customer_name: null,
      paid_at: null,
    },
  },
  renew: {
    dialog: false,
    loading: false,
    form: {
      customer_id: null,
      customer_name: null,
      ftth_subscription_id: "",
      account_type: "",
      price: 0,
      operation_type: "",
      last_expiry_date: null,
      ftth_name: "",
      message: "",
    },
  },
  subscription_customer_ftth_price: {
    price: 0,
    offer_id: null,
    message: null,
    loading: false,
  },
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscriptions: (state, { payload }) => {
      state.subscriptions.data = payload.data;
      state.subscriptions.total = payload.total;
      state.subscriptions.loading = false;
    },
    setSubscriptionPaid: (state, { payload }) => {
      if (payload?.id) {
        state.subscription_pay.form.id = payload.id;
        state.subscription_pay.form.customer_name = payload.customer_name;
        state.subscription_pay.form.paid_at =
          payload.paid_at || dayjs().format("YYYY-MM-DD HH:mm");
      }
      state.subscription_pay.dialog = !state.subscription_pay.dialog;
      //clear after close
      if (!state.subscription_pay.dialog) {
        state.subscription_pay.form = initialState.subscription_pay.form;
      }
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.subscriptions.filters = {
        ...state.subscriptions.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.subscriptions.filters = initialState.subscriptions.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload?.type === "edit") {
        state.subscription.type = "edit";
        state.subscription.form.id = payload.data.id;
        state.subscription.form.is_confirmed = payload.data.is_confirmed
          ? 1
          : 0;

        state.subscription.form.customer_id = {
          id: payload.data.customer_id,
          full_name: payload.data.customer_name,
        };
        state.subscription.form.date = payload.data.date;
        state.subscription.form.account_type_id = {
          id: payload.data.account_type_id,
          name: payload.data.account_type,
          price: payload.data.amount,
        };
        state.subscription.form.last_amount = payload.data.amount;
        state.subscription.form.note = payload.data.note || "";
      }
      if (payload?.type === "add_from_customer") {
        state.subscription.type = "add_from_customer";
        state.subscription.form.customer_id = {
          id: payload.data.id,
          full_name: payload.data.full_name,
        };
      }

      state.subscription.dialog = !state.subscription.dialog;
      //clear after close
      if (!state.subscription.dialog) {
        state.subscription.form = initialState.subscription.form;
        state.subscription.type = "add";
      }
    },
    setFiltersDialog: (state, { payload }) => {
      state.subscriptions.dialog = !state.subscriptions.dialog;
    },
    resetForm: (state) => {
      state.subscription.form = initialState.subscription.form;
    },
    setErrors: (state, { payload }) => {
      state.subscription.errors = payload;
    },
    setSubscriberHistory: (state, { payload }) => {
      state.subscribers_history.data = payload.data;
      state.subscribers_history.loading = false;
    },
    setSubscriptionCustomerFtthPrice: (state, { payload }) => {
      state.subscription_customer_ftth_price.price = payload?.price || 0;
      state.subscription_customer_ftth_price.offer_id =
        payload?.offer_id || null;
      state.subscription_customer_ftth_price.message = payload?.message || "";
    },
    setRenewDialog: (state, { payload }) => {
      state.renew.form.customer_id = payload?.customer_id || null;
      state.renew.form.customer_name = payload?.customer_name || null;
      state.renew.dialog = !state.renew.dialog;
    },
    setRenewInfo: (state, { payload }) => {
      state.renew.form.ftth_subscription_id = payload.ftth_subscription_id;
      state.renew.form.account_type = payload.account_type;
      state.renew.form.price = payload.price;
      state.renew.form.operation_type = payload.operation_type;
      state.renew.form.last_expiry_date = payload.last_expiry_date;
      state.renew.form.ftth_name = payload.ftth_name;
      state.renew.form.message = payload.message;
    },
    resetSubscriptionCustomerFtthPrice: (state) => {
      state.subscription_customer_ftth_price.price = 0;
      state.subscription_customer_ftth_price.offer_id = null;
      state.subscription_customer_ftth_price.message = "";
    },
  },
});

export const {
  setSubscriptions,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setFiltersDialog,
  setSubscriberHistory,
  setSubscriptionPaid,
  setSubscriptionCustomerFtthPrice,
  resetSubscriptionCustomerFtthPrice,
  setRenewDialog,
  setRenewInfo,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().subscriptions.subscriptions.filters;
    const filters_params = {
      ...filters,
      account_type_id: filters.account_type_id?.id || null,
      customer_id: filters.customer_id?.id || null,
      is_paid: filters.is_paid !== null ? (filters.is_paid ? "1" : "0") : null,
    };
    dispatch(setLoading("subscriptions"));
    const res = await subscriptionsApi.index(filters_params);
    dispatch(setSubscriptions(res.data));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("subscriptions"));
    throw new Error(err);
  }
};

export const last = (customer_id) => async (dispatch) => {
  try {
    dispatch(setLoading("subscribers_history"));
    const res = await subscriptionsApi.last(customer_id);
    dispatch(setSubscriberHistory(res.data));
  } catch (err) {
    dispatch(setLoading("subscribers_history"));
    throw new Error(err);
  }
};

const confirm = (id) => async (dispatch, getState) => {
  try {
    // dispatch(setLoading("subscribers_history"));
    await subscriptionsApi.confirm(id);
    dispatch(
      showNotification({
        message: "تم تأكيد الاشتراك بنجاح",
        type: "success",
      })
    );
    dispatch(index());
    // dispatch(setSubscriberHistory(res.data));
  } catch (err) {
    // dispatch(setLoading("subscribers_history"));
    throw new Error(err);
  }
};
export const store = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("subscription"));

    const form = {
      ...data,
      customer_id: data.customer_id ? data.customer_id?.id : null,
      account_type_id: data.account_type_id ? data.account_type_id?.id : null,
      amount: toNumber(data.amount),
      paid_at: data.is_paid ? data.paid_at : null,
    };

    const res = data.id
      ? await subscriptionsApi.update(form)
      : await subscriptionsApi.create(form);
    dispatch(setLoading("subscription"));
    dispatch(
      showNotification({
        message: "تم تجديد الاشتراك بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("subscription"));
    throw new Error(err);
  }
};
const deleteSubscription = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("subscriptions"));
    await subscriptionsApi.deleteSubscription(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف  بنجاح",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(setLoading("subscriptions"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const subscriptionPay = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("subscription_pay"));
    await subscriptionsApi.subscriptionPay({
      id: data.id,
      paid_at: data.paid_at,
    });
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعديل وقت الاشتراك بنجاح",
      })
    );
    dispatch(setLoading("subscription_pay"));
    dispatch(index());
    dispatch(setSubscriptionPaid());
  } catch (err) {
    dispatch(setLoading("subscription_pay"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
export const exportToExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().subscriptions.subscriptions.filters;
    dispatch(setLoading("subscriptions"));
    const res = await subscriptionsApi.exportToExcel({
      ...filters,
      account_type_id: filters.account_type_id?.id || null,
      customer_id: filters.customer_id?.id || null,
      is_paid: filters.is_paid !== null ? (filters.is_paid ? "1" : "0") : null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "subscriptions.xlsx";
    a.click();
    dispatch(setLoading("subscriptions"));
  } catch (err) {
    dispatch(setLoading("subscriptions"));
    throw new Error(err);
  }
};
const fastSubscribe = (task_id) => async (dispatch, getState) => {
  try {
    await taskApi.fastSubscribe(task_id);
    dispatch(
      showNotification({
        message: "تم تجديد الاشتراك بنجاح",
        type: "success",
      })
    );
    return {
      status: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const checkFtthSubscriptionCustomerPrice =
  (customer_id, account_type_id, period) => async (dispatch, getState) => {
    try {
      dispatch(setLoading("subscription_customer_ftth_price"));
      const res = await subscriptionsApi.checkFtthSubscriptionCustomerPrice(
        customer_id,
        account_type_id,
        period
      );
      dispatch(setSubscriptionCustomerFtthPrice(res.data.data[0]));
      dispatch(setLoading("subscription_customer_ftth_price"));
      dispatch(
        showNotification({
          message: "تم جلب السعر",
          type: "info",
        })
      );
    } catch (err) {
      dispatch(setLoading("subscription_customer_ftth_price"));
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };
const checkRenew = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("renew"));
    const res = await subscriptionsApi.checkRenew(id);
    dispatch(setRenewInfo(res.data.data));
    dispatch(setLoading("renew"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("renew"));
    dispatch(setRenewDialog());
    throw new Error(err);
  }
};
const postRenew = () => async (dispatch, getState) => {
  try {
    const data = getState().subscriptions.renew.form;
    dispatch(setLoading("renew"));
    const res = await subscriptionsApi.renew(data);
    dispatch(setLoading("renew"));
    dispatch(setRenewDialog());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("renew"));
    throw new Error(err);
  }
};

export const Subscriptions = {
  index,
  store,
  last,
  confirm,
  deleteSubscription,
  subscriptionPay,
  exportToExcel,
  fastSubscribe,
  checkFtthSubscriptionCustomerPrice,
  checkRenew,
  postRenew,
};
