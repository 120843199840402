import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`client/customer?${p}`);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`client/customer?${p}`, {
      responseType: "blob",
    });
  },
  accuountTypes() {
    return api.get(`client/account-type`);
  },
  getById(id) {
    return api.get(`client/customer/${id}`);
  },
  delete(id) {
    return api.delete(`client/customer/${id}`);
  },
  allStatus() {
    return api.get(`client/status`);
  },
  allCabinet() {
    return api.get(`client/cabinet`);
  },
  updateNote(data) {
    return api.put(`client/customer/update-note/${data.id}`, data);
  },
  updateStatus(data) {
    return api.put(`client/customer/update-status/${data.id}`, data);
  },
  callCenter(id) {
    return api.get(
      `client/call-customer-history?customer_id=${id}&skip=0&take=25`
    );
  },
  addCallNote(data) {
    return api.post(`client/call-customer-history`, data);
  },
  cancelStatusWithAttachment(id, formData) {
    return api.post(`client/customer/update-status/${id}`, formData);
  },
  suspendCustomer(id) {
    return api.put(`client/customer/suspend/${id}`);
  },
  checkCustomerStatus(id) {
    return api.put(`client/customer/update-ftth/${id}`);
  },
  verifyOtpPhone(id, otp) {
    return api.put(`client/customer/otp-validation/${id}`, {
      otp: otp,
    });
  },
  checkDeviceStatus(id) {
    return api.get(`client/customer/device-status/${id}`);
  },
};
